import { HttpParamsConfig } from "@shared/schemas/http-params-config";

function defaultHttpParamsConfig(): HttpParamsConfig {
	return { page: 0, size: 5 };
}

function defaultTotalElements(): number {
	return 0;
}

export { defaultHttpParamsConfig, defaultTotalElements };
